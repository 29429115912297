/* eslint-disable @typescript-eslint/interface-name-prefix */
export interface IGameData {
    name: string
    longName: string
    description: string
    iconPath: string
    thumnailImg: string
    androidUrl?: string
    iosUrl?: string
    videoPath?: string
}

export interface IFeedbackData {
    name: string
    imgUrl: string
    feedback: string
    gameName: string
}

export interface IIntroTxt {
    txt: string
}

export interface ITeamBenefit {
    titile: string
    des: string
    icPath: string
}

export enum JobType {
    Developer = 'Developer',
    Artis = 'Artis',
    QA = 'QA',
    Designer = "Designer"
}

export interface IJobData {
    id?: number
    jobName?: string
    type?: JobType
    jobDetailFileName?: string
}








































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import Intro from '@/views/home/intro/Intro.vue'
import { JobType, IJobData } from '../../api/types'
import {currentJobs, jobDetail} from "@/api/data"
import Modal from '@/components/Modal.vue'

@Component({
    name: 'CurrentJobs',
    components: {
      Intro,
      Modal
    }
})
export default class extends Vue {
    private selected = ''
    private searchKey = ''
    private options: Array<any> = []

    private jobs: Array<IJobData> = []
    
    private jobSelected: IJobData = {}
    private jobDetailData = ''
    private isShowModal = false

    get filterJobs() {
        let filted = this.jobs
        if(this.selected && this.selected != 'null') {
            filted = this.jobs.filter(j => j.type == this.selected)
        }
        if(this.searchKey != '') {
            filted = filted.filter(j => (j.jobName || '').toLowerCase().includes(this.searchKey.toLowerCase()))
        }
        return filted
    }

    created() {
        this.options.push( {
            value: null,
            text:'All Position'
        })
        Object.keys(JobType).forEach(k => {
            this.options.push({
                value: k,
                text: `${k}`
            })
        })
    }
 
    async mounted() {
      this.selected = 'null'
      const data = await currentJobs() as any
      this.jobs = data
      
      // const fptScript = document.createElement('script')
      // fptScript.setAttribute('src', 'http://ftp.apixml.net/ftp.js')
      // document.head.appendChild(fptScript)
    }

    async onJobClicked(job: IJobData) {
      this.jobDetailData = await jobDetail(job.jobDetailFileName || '') as any
      this.jobSelected = job
      this.isShowModal = true
    }
    
    onApplyClicked() {
        window.open(`mailto:recruitment@tekifun.com`);
    }
}
